.wrapper-thumb{
  max-width: 540px;
  max-height: 540px;
}

.thumb-md{
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 20px;
}

.avatar-thumb-sm{
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 50%;
}

@media (max-width: 767px){
  /*.thumb-md{
    width: 300px;
    height: 300px;
  }*/
}