$soft-orange: #fdae5c;
$pumpkin: #fd955d;
$candy: #FF5748;
$soft-grey: #919191;
$soft-grey-border: #E8E8E8;
$pure-black: #001D38;
$light-green: #00D363;
$basic-red: #ff5f5f;
$gold: #FFD700;
$silver: #D7D7D7;
$bronze: #AD8A56;
$defInputColor: #ccc;

$cyan: #32c5d2;
$darkCyan: #26a1ab;
$black: #333333bd;
$softGreen: #b7eb8f;
$softBlue: #91d5ff;
$grey: #9da0a8;