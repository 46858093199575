@import "style/components/_variable";
@import "style/components/_home";
@import "style/components/_footer";
@import "style/components/_typography";
@import "style/components/_card";
@import "style/components/_contactUs";
@import "style/components/_image";
@import "style/components/_accordion";
@import "style/components/_shared";
@import "style/components/_button";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body, html {
    height: 100%;
    color: $black;
}

svg { vertical-align: baseline; }

#root{
  height: 100vh;
  background-color: #364150;
}

a{
  color: $soft-orange;
}

a:hover{
  color: $soft-orange;
}

.main-wrapper{
      padding-top: 100px;
      padding-bottom: 90px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tooltip-inner {
  background-color: $soft-orange !important;
}

.cursor-pointer{
  cursor: pointer;
}

.admin-name{
  color: $darkCyan;
}

.arrow{
  &:before{
    border-top-color: $soft-orange !important;
  }
}

.ant-layout-content{
  min-height: unset !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-pg{
  h1, h2, h3, h4{
    color: $darkCyan;
  }
}

.dropdown-profile {
  left: auto !important;
  right: 10px;
  min-width: fit-content !important;
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: #fff !important;
    background: #1890ff !important;
    box-shadow: 2px 0 8px rgba(0,0,0,.15);
}

.m{
  &-b{
    &-0{
      margin-bottom: 0 !important;
    }
    &-10{
      margin-bottom: 10px !important;
    }
    &-20{
      margin-bottom: 20px !important;
    }
    &-30{
      margin-bottom: 30px !important;
    }
    &-40{
      margin-bottom: 40px !important;
    }
  }
  &-t{
    &-0{
      margin-top: 0 !important;
    }
    &-10{
      margin-top: 10px !important;
    }
    &-20{
      margin-top: 20px !important;
    }
    &-24{
      margin-top: 24px !important;
    }
    &-30{
      margin-top: 30px !important;
    }
    &-40{
      margin-top: 40px !important;
    }
  }
  &-r{
    &-0{
      margin-right: 0 !important;
    }
    &-10{
      margin-right: 10px !important;
    }
    &-20{
      margin-right: 20px !important;
    }
    &-30{
      margin-right: 30px !important;
    }
    &-40{
      margin-right: 40px !important;
    }
  }
  &-l{
    &-0{
      margin-left: 0 !important;
    }
    &-10{
      margin-left: 10px !important;
    }
    &-20{
      margin-left: 20px !important;
    }
    &-30{
      margin-left: 30px !important;
    }
    &-40{
      margin-left: 40px !important;
    }
  }
}

.p{
  &-b{
    &-0{
      padding-bottom: 0 !important;
    }
    &-10{
      padding-bottom: 10px !important;
    }
    &-20{
      padding-bottom: 20px !important;
    }
    &-30{
      padding-bottom: 30px !important;
    }
    &-40{
      padding-bottom: 40px !important;
    }
  }
  &-t{
    &-0{
      padding-top: 0 !important;
    }
    &-10{
      padding-top: 10px !important;
    }
    &-20{
      padding-top: 20px !important;
    }
    &-30{
      padding-top: 30px !important;
    }
    &-40{
      padding-top: 40px !important;
    }
  }
  &-r{
    &-0{
      padding-right: 0 !important;
    }
    &-10{
      padding-right: 10px !important;
    }
    &-20{
      padding-right: 20px !important;
    }
    &-30{
      padding-right: 30px !important;
    }
    &-40{
      padding-right: 40px !important;
    }
  }
  &-l{
    &-0{
      padding-left: 0 !important;
    }
    &-10{
      padding-left: 10px !important;
    }
    &-20{
      padding-left: 20px !important;
    }
    &-30{
      padding-left: 30px !important;
    }
    &-40{
      padding-left: 40px !important;
    }
  }
}

.h-100-vh{
  min-height: 100vh;
}

.scrollable-register{
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-sla{
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.swal2-close{
  top: -5px !important;
  right: -5px !important;
}

.card-sla{
  height: 100%
}

.text-container-solution{
  max-width: 100%;
  min-width: 400px;
  width: 500px;
  white-space: 'wrap';
}

.text-trimmed-solution{
  text-overflow: 'ellipsis';
  width: 100%;
}