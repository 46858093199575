.footer{
	background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #F5FBFF;
}

.footer-widget, p{
	color: $soft-grey;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  a{
  	color: #fdae5c;
  }
}

.footer-top{
	padding-top: 145px;
	padding-bottom: 129px;
}

.footer-title{
	font-size: 18px;
  font-weight: 500;
  color: #001D38;
  text-transform: capitalize;
}

.list-footer{
  font-size: 13px;
  line-height: 42px;
	margin: 0px;
  padding: 0px;
	li{
		list-style: none;
	}
	a{
		color: $soft-grey;
	}
}

.newsletter-form{
	position: relative;
  margin-bottom: 20px;
  .custom-input-footer{
  	width: 100%;
    height: 50px;
    background: #F5FBFF;
    padding-left: 20px;
    font-size: 15px;
    color: #000;
    border: none;
    border: 1px solid $soft-grey-border;
    border-radius: 30px;
  }
  .custom-btn-footer{
  	position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    border: none;
    font-size: 14px;
    color: #fff;
    background: $pumpkin;
    padding: 10px;
    padding: 0 22px;
    cursor: pointer;
    border-radius: 30px;
    top: 5px;
    right: 5px;
    font-size: 13px;
    font-weight: 500;
  }
}