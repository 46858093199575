.cover-blank-wrapper{
	background-size: cover;
    background-position: center center;
    padding: 282px 0 120px 0;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    &:before{
    	position: absolute;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    background: #001D38;
	    opacity: .6;
	    z-index: -1;
	    content: '';
    }
}

.cover-title{
	h3{
		font-size: 42px;
		color: #fff;
		font-weight: 600;
		margin-bottom: 0;
		text-transform: capitalize;
	}
}

.contact-title{
	font-size: 27px;
    font-weight: 500;
    margin-bottom: 20px;
}

.content-wrapper{
	padding: 130px 0 100px;
}

.contact-info{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 25px;
    .contact-icon{
    	width: 30px;
    	margin-right: 20px;
    }
    .media-contact-info{
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		h3{
			margin-bottom: 0;
			font-size: 18px;
			color: #2a2a2a;
		}
		p{
			color: #8a8a8a;
		}
	}
}

@media (max-width: 767px){
  .content-wrapper{
    padding: 40px 0px 100px;
  }
}