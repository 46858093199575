.cover-wrapper{
	position: relative;
  z-index: 0;
	&:before{
		background: #001D38;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: .6;
	}
}

.single-cover{

}

.img-home{
	height: 900px;
	width: 100%;
  background-size: cover;
  background-position: 50%;
  background-color: #eee;
}

.img-home-detail{
	height: 657px;
	width: 100%;
	background-size: cover;
    background-position: center center;
    padding: 140px 0;
}

.slider-text{
  h3{
    font-size: 70px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 600;
    line-height: 70px;
    margin-bottom: 15px;
    color: #fff;
  }
  p{
    font-size: 20px;
    font-weight: 400;
    color: #FFF;
    margin-bottom: 24px;
    margin-top: 10px;
  }
}

.property-filter{
  padding: 30px;
  background: rgba(0, 28, 56, 0.6);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.w-otp {
  width: 3em !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}

@media (max-width: 767px){
  .slider-text{
    h3{
      font-size: 30px;
      line-height: 45px;
    }
    p{
      font-size: 16px;
    }
  }
}