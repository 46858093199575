.card-login{
  border-radius: 10px;
  border-color: transparent;
  .divider{
    text-align: center;
    margin: 15px 0px 15px !important;
    border-bottom: 1px solid #ddd;
    line-height: 0.1em;
    span{
      padding: 0 10px;
      background: #fff;
      color: #ddd;
    }
  }
}

.custom-card{
  box-shadow: 0 3px 4px 0 rgba(33,35,68,.28);
}

@media (max-width: 767.98px) {
  .total-section{
    h4{
      font-size: 20px;
    }
    h5{
      font-size: 15px;
    }
  }
}