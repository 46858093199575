.soft-green-bg{
  background-color: $softGreen !important;
}

.soft-blue-bg{
  background-color: $softBlue !important;
}

.gry-font{
  color: $grey;
}

.scrollable-card {
  .ant-card-body {
    height: 300px;
    max-height: 300px;
    overflow-y: scroll;
    overflow: auto;
  }
}

.siplah-custom-logo {
  max-width: 340px;
  width: 100%;
  height: auto;
}

.custom-checkbox {
  .ant-checkbox + span {
    position: absolute;
  }
}

// .ant-select-multiple
// {
//    white-space: nowrap;
//    height: 35px;
//    overflow: auto
// }

.eula-content {
  p {
    color: rgb(36, 36, 36);
    margin-bottom: 6px;
    line-height: 22px;
  }
  .alphabet-list {
    list-style-type:lower-alpha;
  }
}
