.dark-cyan-font{
  color: $darkCyan;
}

.text-red {
  color: #ea3323;
}

.content-p{
  font-size: 16px;
  color: $black;
  font-weight: 600;
  span{
    font-weight: 400;
  }
}