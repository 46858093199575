.accordion-card{
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: .25rem;
  border-bottom: 1px solid $soft-grey-border !important;
}
.accordion-header{
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.accordion-body{
  font-size: 15px;
  line-height: 28px;
  color: $grey;
  font-weight: 400;
  line-height: 28px;
}