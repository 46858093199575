.custom-primary{
  background-color: $cyan;
  border-color: $cyan;
}

.custom-primary:hover{
  background-color: $darkCyan;
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.54);
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.54);
}

.custom-primary:active{
  background-color: $darkCyan !important;
  border-color: transparent !important;
}

.custom-primary:focus{
  background-color: $darkCyan;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.54);
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.54);
}
